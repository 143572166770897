
import { defineComponent } from "vue";
import AppToaster from "@/components/AppToaster.vue";
import { mapState } from "vuex";

export default defineComponent({
  components: { AppToaster },
  computed: {
    ...mapState("theme", ["theme"]),
    ...mapState("meta", ["title"]),
  },
  watch: {
    theme(theme: string) {
      if (theme === "dark") {
        document.body.classList.add("dark-theme");
      } else {
        document.body.classList.remove("dark-theme");
      }
    },
    title(title: string) {
      if (title) {
        document.title = `${title} - DEMO ERP`;
      } else {
        document.title = "DEMO ERP";
      }
    },
  },
  created() {
    if (this.$store.state.theme.theme === "dark") {
      document.body.classList.add("dark-theme");
    } else {
      document.body.classList.remove("dark-theme");
    }
    if (this.$store.state.meta.title) {
      document.title = `${this.$store.state.meta.title} - DEMO ERP`;
    } else {
      document.title = "DEMO ERP";
    }
  },
});
